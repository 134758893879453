import React, { useEffect, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import Icon from '../Icon';
import { SelectButton } from 'primereact/selectbutton';

const DetailTimelineDialog = ({details, visible,onHide }) => {
  console.log(details);
    const [selectedOperationValue, setSelectedOperationValue] = useState(details?.operations);
    const [selectedCapacity, setSelectedCapacity] = useState(details?.capacity);
    const [selectedPDCapacity, setSelectedPDCapacity] = useState(details?.pickup_and_delivery);
    const [selectedstaff, setSelectedStaff] = useState(details?.staffing);
    useEffect(()=>{
        setSelectedOperationValue(details?.operations)
        setSelectedCapacity(details?.capacity)
        setSelectedPDCapacity(details?.pickup_and_delivery)
        setSelectedStaff(details?.staffing)
    },[details])
    const DetailModalHide=()=>{
        // setSelectedOperationValue()
        // setSelectedCapacity()
        // setSelectedPDCapacity()
        // setSelectedStaff()
        onHide();
    }
    const handleSelectChange = () => {
       
      };
    const operationitems = [
        { name: "Open", value: 1, className: "option-open" },
        { name: "Limited", value: 2, className: "option-limited" },
        { name: "Closed", value: 3, className: "option-closed" },
        
      ];
    
      const capacityitemss = [
        { name: "Normal", value: 1, className: "option-normal" },
        { name: "Crowded", value: 2, className: "option-crowded" },
        { name: "Over Crowded", value: 3, className: "option-overcrowded" },
      ];
      const staffnumbers= [
        { name: "100%", value: 100, className: "option-100" },
        { name: "75%", value: 75, className: "option-75" },
        { name: "50%", value: 50, className: "option-50" },
        { name: "25%", value: 25, className: "option-25" },
        { name: "0%", value: 0, className: "option-0" },
      ];
      const pdcapacity= [
        { name: "100%", value: 100, className: "option-100" },
        { name: "75%", value: 75, className: "option-75" },
        { name: "50%", value: 50, className: "option-50" },
        { name: "25%", value: 25, className: "option-25" },
        { name: "0%", value: 0, className: "option-0" },
      ];
    const headertemplate = () => {
        return <div className="modal-title">Status Details -{details?.created_at_custom_format}
        <span className='timezone-label ml-2'>All times are in {details?.user_timezone}</span></div>;
      };
    return (
        <Dialog 
        className="status-modal"
        visible={visible}
        onHide={DetailModalHide}
        header={headertemplate}
        modal={true}
        closable
        closeIcon={  <Icon name='cross-black' type='svg' />}
        closeOnEscape={false}
        dismissableMask={false}
        draggable={false}
        style={{ width: "810px", height: "740px" }}
 >
          <div className="grid ">
          <div className="col-12 flex flex-column gap-2 status-form">
            <label className="statusform-label">
              Location
            </label>
            <label className="statusdetail-label">
             {details?.location_name}
            </label>
          
          </div>
          <div className="col-6 flex flex-column gap-2 operation-selected-button custom-select-button mt-2">
            <label className="statusform-label">
              How operations are running today?
              <span style={{ color: "red" }}> *</span>
            </label>
            <SelectButton
              value={selectedOperationValue}
              onChange={handleSelectChange}
              options={operationitems}
              optionLabel="name"
              itemTemplate={(option) => (
                <span
                  className={`${option.className} ${
                    selectedOperationValue === option.value ? "p-highlight" : ""
                  }`}
                >
                  {option.name}
                </span>
              )}
            />
          </div>
          <div className="col-6 flex flex-column gap-2 dock-selected-button custom-select-button mt-2">
            <label className="statusform-label">
              How is your dock capacity?<span style={{ color: "red" }}> *</span>
            </label>
            <SelectButton
              value={selectedCapacity}
              onChange={handleSelectChange}
              options={capacityitemss}
              optionLabel="name"
              itemTemplate={(option) => (
                <span
                  className={`${option.className} ${
                    selectedCapacity === option.value ? "p-highlight" : ""
                  }`}
                >
                  {option.name}
                </span>
              )}
            />
          </div>
          <div className="col-6 flex flex-column gap-2 operation-selected-button custom-select-button mt-2">
            <label className="statusform-label">
            How is your staffing today?
              
            </label>
            <SelectButton
              value={selectedstaff}
              onChange={handleSelectChange}
              options={staffnumbers}
              optionLabel="name"
              itemTemplate={(option) => (
                <span
                  className={`${option.className} ${
                    selectedOperationValue === option.value ? "p-highlight" : ""
                  }`}
                >
                  {option.name}
                </span>
              )}
            />
          </div>
          <div className="col-6 flex flex-column gap-2 dock-selected-button custom-select-button mt-2">
            <label className="statusform-label">
            How is your P&D capacity?
            </label>
            <SelectButton
              value={selectedPDCapacity}
              onChange={handleSelectChange}
              options={pdcapacity}
              optionLabel="name"
              className="custom-select-button"
              itemTemplate={(option) => (
                <span
                  className={`${option.className} ${
                    selectedCapacity === option.value ? "p-highlight" : ""
                  }`}
                >
                  {option.name}
                </span>
              )}
            />
          </div>
          <div className="col-12 flex flex-column gap-2 mt-2">
            <label className="statusform-label">
            How many days of work you are behind?
            </label>
            <div className="statusdetail-label">
            {details?.backlog}  {details?.backlog===1?"Day":"Days"}
          
            </div>
          </div>
          <div className="col-12 flex flex-column gap-2 ">
            <label className="statusform-label">
            Issue Faced
            </label>
            <div className="statusdetail-label">
            {details?.issue_detail} 
          
            </div>
          </div>
          <div className="col-12 flex flex-column gap-2 ">
            <label className="statusform-label">
            Comment
            </label>
            <div className="statusdetail-label">
            {details?.comment?details?.comment:"-"}
          
            </div>
          </div>
        </div>
            {/* <p className="m-0">
                <div className="grid timeline-dialog-label">
                <div className="col-5">
                    <label htmlFor="" className='t-w-7' >Operations</label>
                   <p className='mt-1'>{details?.operation_status?details?.operation_status:"-"}</p>
                 
                </div>
                <div className="col-5">
                    <label htmlFor="" className='t-w-7'>Dock Capacity</label>
                    <p className='mt-1'>{details?.capacity_status?details?.capacity_status:"-"}</p>
                 
                </div>
                <div className="col-2">
                    <label htmlFor="" className='t-w-7'>Staffing</label>
                    <p className='mt-1'>{details?.staffing?details?.staffing:"-"}%</p>
                 
                </div>
                <div className="col-5">
                    <label htmlFor="" className='t-w-7'>P&D Capacity</label>
                   <p className='mt-1'>{details?.pickup_and_delivery?details?.pickup_and_delivery:"-"}%</p>
                 
                </div>
                <div className="col-5">
                    <label htmlFor="" className='t-w-7'>Issue Faced</label>
                    <p className='mt-1'>{details?.issue?details?.issue:"-"}</p>
                 
                </div>
                <div className="col-2">
                    <label htmlFor="" className='t-w-7'>Days Behind</label>
                    <p className='mt-1'>{details?.backlog}{details?.backlog === 1 ? " Day" : " Days"}</p>
                 
                </div>
                <div className="col-12">
                    <label htmlFor="" className='t-w-7'>Comment</label>
                   <p className='mt-1 timeline-dialog-comment'>{details?.comment?details?.comment:"-"}</p>
                 
                </div>
                </div>
                
            </p> */}
        </Dialog>
    );
};

export default DetailTimelineDialog;
