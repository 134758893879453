import React from 'react'
import { Button } from 'primereact/button'
import Icon from './Icon'
import { Tooltip } from "primereact/tooltip";
const PageHeader = ({title,actions,className}) => {
  return (
    <div className='mb-1'>
        <div className='flex justify-content-between align-items-center'>
            <div className={`primary-text ${className}`}>{title}</div>
            <div className='flex align-items-center md:text-base text-sm'>
                {
                    actions?.map((action, i) => (
                       
                            // action.type == 'input' ?
                            //     <span key={i} className="m-0 px-2 flex align-items-center action-input">

                            //         <InputLabel className={action.name && 'label-text md:text-base text-sm pr-2'} value={action.name} />
                            //         <span className="m-0 flex align-items-center">
                            //             <Icon className="search-icon pt-1 pl-2" type="svg" name="search" />
                            //             <InputText value={action.state} onChange={(e) => action.setState(e.target.value)} placeholder={action.placeholder} className={'search-input w-12 ' + action.className} />
                            //         </span>
                            //     </span>
                            //     :
                           
                                action.type === 'icon' ?
                                    <div key={i} onClick={action.onClick} data-pr-tooltip="Download" data-pr-position="top" className="mx-2 cursor-pointer flex align-items-center justify-content-center icon-button icon-download" style={{ pointerEvents: action.disabled ? 'none' : 'auto' }}>
                                <Tooltip className={'primary-tooltip'} target={`.icon-download`} />
                                 <Icon className={` ${action.disabled ? 'opacity-50' : ''}`} name='download' type='svg' />
                                    </div>
                                    :
                                    action.type === 'button' ?
                                        <Button onClick={action.onClick} disabled={action.disabled} key={i} className={action.className}>
                                            {action.name}
                                        </Button>
                                        :
                                        null
                    ))
                }
            </div>
        </div>
    </div>
  )
}

export default PageHeader