import { forwardRef, useEffect, useRef } from 'react';
import { Password } from 'primereact/password';
import Icon from "./Icon";
export default forwardRef(function PasswordInput({ type = 'text', className = '', isFocused = false, ...props }, ref) {
    const localRef = useRef();
    const input = ref || localRef;
    useEffect(() => {
        if (isFocused) {
            input.current.focus();
        }
    }, []);
    const hideIconTemplate = () => {
        return (
            <Icon onClick={() => input.current?.toggleMask()} name='eye-open' type='svg' />
        )
    }
    const showIconTemplate = () => {
        return (
            <Icon onClick={() => input.current?.toggleMask()} name='eye-close' type='svg' />
        )
    }

    return (
        <Password
            {...props}
            type={type}
            className={
                'border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 rounded-md shadow-sm ' +
                className
            }
            ref={input}
            toggleMask={true}
            feedback={false}
            showIcon={showIconTemplate}
            hideIcon={hideIconTemplate}
        />
    );
});
