import React, { useState } from "react";
import { Dialog } from 'primereact/dialog';
import { FileUpload } from 'primereact/fileupload';
import Cropper from "react-easy-crop";

import PrimaryButton from "../PrimaryButton";
import Icon from "../Icon";
export default function UploadImageModal({ visible, setVisible, width = '50vw', onFileUpload }) {

    // image to be selected
    const [image, setImage] = useState("");
    const [imageName, setImageName] = useState("");
    // image crop states
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [croppedArea, setCroppedArea] = useState(null);
    const [aspectRatio, setAspectRatio] = useState(1);
    // to change image crop mode
    const [isImageSelected, setIsImageSelected] = useState(false);

    const [fileValidationMessage, setFileValidationMessage] = useState({
        error: false,
        message: ""
    });

    const closeModal = () => {
        setImage("");
        setImageName("");
        setIsImageSelected(false);
        setVisible(false);
    }

    // when image is selected -> then handle the image for further crop function
    const handleFileChange = (event) => {
        if (event.files && event.files.length > 0) {
            const file = event.files[0];
            const maxSizeInBytes = 2 * 1024 * 1024; // 1MB
            const allowedTypes = ['image/png', 'image/jpeg'];
            if (file.size > maxSizeInBytes) {
                // File size exceeds the limit, show an error message
                setFileValidationMessage({ error: true, message: "File size exceeds the limit. Please select a file smaller than 2MB." });
            } else if (!allowedTypes.includes(file.type)) {
                // File type is not PNG or JPEG, show an error message
                setFileValidationMessage({ error: true, message: "Wrong file format. Please upload a JPG or PNG file." });
            } else {
                setImageName(file?.name)
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = function (e) {
                    setImage(reader.result)
                    setIsImageSelected(true);
                };
                setFileValidationMessage({ error: false, message: "" });
            }
        }
    }

    // on crop complete set cropped area
    const onCropComplete = (croppedAreaPercentage, croppedAreaPixels) => {
        setCroppedArea(croppedAreaPixels);
    };

    // Generating Cropped Image When Done Button Clicked
    const onUploadDone = () => {
        const canvasEle = document.createElement("canvas");
        canvasEle.width = croppedArea.width;
        canvasEle.height = croppedArea.height;

        const context = canvasEle.getContext("2d");

        let imageObj1 = new Image();
        imageObj1.src = image;
        imageObj1.onload = function () {
            context.drawImage(
                imageObj1,
                croppedArea.x,
                croppedArea.y,
                croppedArea.width,
                croppedArea.height,
                0,
                0,
                croppedArea.width,
                croppedArea.height
            );
            const dataURL = canvasEle.toDataURL("image/jpeg");

            // Split the base64 string to get the content type and data
            const parts = dataURL.split(';base64,');
            const contentType = parts[0].split(':')[1];
            const raw = window.atob(parts[1]);
            const rawLength = raw.length;
            const uInt8Array = new Uint8Array(rawLength);

            for (let i = 0; i < rawLength; ++i) {
                uInt8Array[i] = raw.charCodeAt(i);
            }

            // Create a Blob object
            const blob = new Blob([uInt8Array], { type: contentType });

            // Create a File object from the Blob
            const file = new File([blob], imageName, { type: contentType });

            // Now you have the file object
            onFileUpload(file)
            closeModal();
        };
    };
    // Handle Cancel Button Click
    const onCropCancel = () => {
        if (isImageSelected) {
            setImage("");
            setImageName("");
            setIsImageSelected(false);
        }
        else {
            setFileValidationMessage({ error: false, message: "" });
            closeModal();
        }
    };

    // template for choose image label
    const chooseFileTemplate = () => {
        return (
            <>
                <div className="flex flex-column align-items-center justify-content-center text-lg">
                <Icon name='upload' type='svg' />
                    <span className="flex text-lg pt-2 label-text secondary-text">
                       Choose file to upload
                    </span>
                    <span className="pt-2 text-sm tertiary-text">
                    (Image must not exceed 2MB)
                    </span>  
                </div>
            </>
        )
    }
   
    return (
        <div className="card flex justify-content-center align-items-center">
            <Dialog header={'Upload Image'} visible={visible} modal={true} style={{ width: width }} onHide={closeModal} closable closeOnEscape closeIcon={  <Icon name='cross-black' type='svg' />} draggable={false}>
                {
                    fileValidationMessage.error ?
                        <div className="m-4 p-4 flex flex-column align-items-center justify-content-center text-lg">
                            <p className={`text-center text-red-500`}>
                                {fileValidationMessage.message}
                            </p>
                            <p className="cursor-pointer secondary-text" onClick={() => setFileValidationMessage({ error: false, message: "" })}>Go Back</p>

                        </div>
                        :
                        !isImageSelected ? (
                            <div className="m-4 p-4 flex flex-column align-items-center justify-content-center text-lg">
                                <FileUpload chooseOptions={{ icon: chooseFileTemplate, iconOnly: true }} className="upload-link pr-1" mode="basic" name="demo[]" accept=".jpg,.png" customUpload uploadHandler={handleFileChange} auto />
                            </div>

                        ) : (
                            <div className="m-8 p-8">
                                <div className="flex justify-content-center">
                                    <Cropper
                                        image={image}
                                        aspect={aspectRatio}
                                        crop={crop}
                                        zoom={zoom}
                                        onCropChange={setCrop}
                                        onZoomChange={setZoom}
                                        onCropComplete={onCropComplete}
                                        cropShape={'round'}
                                        style={{
                                            containerStyle: {
                                                width: "98%",
                                                height: "60%",
                                                backgroundColor: "#fff",
                                                margin: '80px 5px'
                                            },
                                        }}
                                    />
                                </div>
                            </div>
                        )
                }
                <div className="mt-2 flex justify-content-center">
                    <PrimaryButton className='tertiary-button md:text-base text-sm' onClick={onCropCancel}>{isImageSelected ? 'Back' : 'Cancel'}</PrimaryButton>
                    <PrimaryButton disabled={!isImageSelected} className='primary-button md:text-base text-sm ml-2' onClick={onUploadDone}>Done</PrimaryButton>
                </div>
            </Dialog>
        </div>
    )
}