import React from 'react';


export const StatusSection = ({activeColumn, onColumnClick,totalcount, isZoomed }) => {
  const handleClick = (columnName) => {
    onColumnClick(columnName,isZoomed);
  };

  const getColumnClassName = (columnName) => {
    return activeColumn === columnName ? 'border-2' : 'border-2';
  };

  const determinepercentage=(total,count)=>{
    if (total > 0) {
      const percentage = (count / total) * 100;
      return percentage === 100 ? "100" : percentage.toFixed(2);
    }
    return "0.0";
  };
  return (
    <>  
    <div>
      { !isZoomed?
      (<div className='surface-0 shadow-2 px-3  border-1 border-50  mb-1' style={{borderRadius:"10px"}}>
      <div className='my-2 status-grid'>

      <div className={`surface-0  p-2  cursor-pointer ${activeColumn === 'All' ? 'border-all' : 'custom-border'} ${getColumnClassName('All')}`} onClick={() => handleClick('All')} style={{borderRadius:"14.05px"}}>
          <div className="flex justify-content-between ">
          <div className='top-title-font'>
            <span className="block mb-3 uppercase ">All Locations </span>
            <div className="text-left" style={{color:"#696969" }}>{totalcount?.All?.count}</div>
            </div>
           
          </div>
        </div>

<div className={`surface-0  p-2  cursor-pointer ${activeColumn === 'Open' ||activeColumn === 'All' ? 'border-open' : 'custom-border'} ${getColumnClassName('Open')}`} onClick={() => handleClick('Open')} style={{borderRadius:"14.05px"}}>
          <div className="flex justify-content-between ">
          <div className='top-title-font'>
            <span className="block mb-3 uppercase ">Open </span>
            <div className="text-left" style={{color:"#696969" }}>{totalcount?.Open?.count}</div>
            </div>
            <div className="mt-2" style={{width:"auto",height:"40px",borderRadius:"8px",padding:"5px", backgroundColor: "#107E5F14" }}>
            <span className='percent-font' style={{color: "#107E5F" }}>{`${determinepercentage(totalcount?.Total,totalcount?.Open?.count)}%`}</span>
            </div>
          </div>
        </div>
        <div className={`surface-0  p-2   cursor-pointer ${activeColumn === 'Limited' ||activeColumn === 'All' ? 'border-limited' : 'custom-border'} ${getColumnClassName('Limited')}`} onClick={() => handleClick('Limited')}style={{borderRadius:"14.05px",}}>
          <div className="flex justify-content-between ">
          <div className='top-title-font'>
              <span className="block mb-3 uppercase " >Limited</span>
              <div className="text-left" style={{ color:"#696969" }}>{totalcount?.Limited?.count}</div>
            </div>
            <div className="mt-2" style={{width:"auto",height:"40px",borderRadius:"8px",padding:"5px", backgroundColor: "#238EF114" }}>
            <span className='percent-font' style={{color: "#238EF1"}}>{`${determinepercentage(totalcount?.Total,totalcount?.Limited?.count)}%`}</span>
            </div>
          </div>
        </div>

      
        <div className={`surface-0 p-2  cursor-pointer  ${activeColumn === 'Closed' ||activeColumn === 'All' ? 'border-closed' : 'custom-border'} ${getColumnClassName('Closed')}`} onClick={() => handleClick('Closed')} style={{borderRadius:"14.05px",}}>
          <div className="flex justify-content-between ">
          <div className='top-title-font'>
              <span className="block mb-3 uppercase ">Closed</span>
              <div className="text-left" style={{ color:"#696969" }}>{totalcount?.Closed?.count}</div>
            </div>
            <div className="mt-2" style={{width:"auto",height:"40px",borderRadius:"8px",padding:"5px", backgroundColor: "#FF262614" }}>
              <span className='percent-font' style={{color: "#FF2626"}}>{`${determinepercentage(totalcount?.Total,totalcount?.Closed?.count)}%`}</span>
            </div>         
          </div>
        </div> 
        <div className={`surface-0  p-2   cursor-pointer ${activeColumn === 'Missing' ||activeColumn === 'All' ? 'border-missing' : 'custom-border'} ${getColumnClassName('Missing')}`} onClick={() => handleClick('Missing')} style={{borderRadius:"14.05px",}}>
          <div className="flex justify-content-between ">
            <div className='top-title-font'>
            <span className="block mb-3 uppercase">Missing</span>
            <div className="text-left" style={{color:"#696969" }}>{totalcount?.Missing?.count}</div>
            </div>
            <div className="mt-2" style={{width:"auto",height:"40px",borderRadius:"8px",padding:"5px", backgroundColor: "#80808014" }}>
              <span className='percent-font' style={{color: "#808080"}}>{`${determinepercentage(totalcount?.Total,totalcount?.Missing?.count)}%`}</span>
            </div>
          </div>
        </div>
      </div>
      </div>)
      
      :
      (
      <div className='surface-0 shadow-2 px-3  border-1 border-50  mb-1' style={{borderRadius:"10px"}}> 
      <div className='my-2 status-grid'>
      
      <div className={`surface-0 p-2 cursor-pointer ${activeColumn === 'All' ? 'border-all' : 'custom-border'} ${getColumnClassName('All')}`} onClick={() => handleClick('All')} style={{ borderRadius: "14.05px" }}>
        <div className="flex align-items-center justify-content-between" style={{ height: "100%" }}>
        <div className="zoomed-top-title-font flex align-items-center gap-2">
          <span className="block uppercase ">All Locations</span>
          <div className="text-left" style={{ color: "#696969" }}>{totalcount?.All?.count}</div>
        </div>
        </div>
    </div>

      <div className={`surface-0  p-2   cursor-pointer ${activeColumn === 'Open' ||activeColumn === 'All' ? 'border-open' : 'custom-border'} ${getColumnClassName('Open')}`} onClick={() => handleClick('Open')} style={{borderRadius:"14.05px"}}>
          <div className="flex align-items-center justify-content-between ">
          <div className='zoomed-top-title-font flex gap-2'>
            <span className="block  uppercase ">Open </span>
            <div className="text-left" style={{color:"#696969" }}>{totalcount?.Open?.count}</div>
            </div>
            <div className="flex align-items-center align-self-start justify-content-center p-2" style={{width:"auto",height:"20px",borderRadius:"8px", backgroundColor: "#107E5F14" }}>
            <span className='zoomed-percent-font' style={{color: "#107E5F" }}>{`${determinepercentage(totalcount?.Total,totalcount?.Open?.count)}%`}</span>
            </div>
          </div>
        </div>

        <div className={`surface-0  p-2   cursor-pointer ${activeColumn === 'Limited' ||activeColumn === 'All' ? 'border-limited' : 'custom-border'} ${getColumnClassName('Limited')}`} onClick={() => handleClick('Limited')}style={{borderRadius:"14.05px",}}>
          <div className="flex align-items-center justify-content-between ">
          <div className='zoomed-top-title-font flex gap-2'>
              <span className="block  uppercase " >Limited</span>
              <div className="text-left" style={{ color:"#696969" }}>{totalcount?.Limited?.count}</div>
            </div>
            <div className="flex align-items-center align-self-start justify-content-center p-2" style={{width:"auto",height:"20px",borderRadius:"8px", backgroundColor: "#238EF114" }}>
            <span className='zoomed-percent-font' style={{color: "#238EF1"}}>{`${determinepercentage(totalcount?.Total,totalcount?.Limited?.count)}%`}</span>
            </div>
          </div>
        </div>

        <div className={`surface-0 p-2  cursor-pointer  ${activeColumn === 'Closed' ||activeColumn === 'All' ? 'border-closed' : 'custom-border'} ${getColumnClassName('Closed')}`} onClick={() => handleClick('Closed')} style={{borderRadius:"14.05px",}}>
          <div className="flex align-items-center justify-content-between">
          <div className='zoomed-top-title-font flex gap-2'>
              <span className="block  uppercase ">Closed</span>
              <div className="text-left" style={{ color:"#696969" }}>{totalcount?.Closed?.count}</div>
            </div>
            <div className="flex align-items-center align-self-start justify-content-center p-2" style={{width:"auto",height:"20px",borderRadius:"8px", backgroundColor: "#FF262614" }}>
              <span className='zoomed-percent-font' style={{color: "#FF2626"}}>{`${determinepercentage(totalcount?.Total,totalcount?.Closed?.count)}%`}</span>
            </div>         
          </div>
        </div>

        <div className={`surface-0  p-2   cursor-pointer ${activeColumn === 'Missing' ||activeColumn === 'All' ? 'border-missing' : 'custom-border'} ${getColumnClassName('Missing')}`} onClick={() => handleClick('Missing')} style={{borderRadius:"14.05px",}}>
          <div className="flex align-items-center justify-content-between">
            <div className='zoomed-top-title-font flex gap-2'>
            <span className="block uppercase">Missing</span>
            <div className="text-left" style={{color:"#696969" }}>{totalcount?.Missing?.count}</div>
            </div>
            <div className="flex align-items-center align-self-start justify-content-center p-2" style={{width:"auto",height:"20px",borderRadius:"8px", backgroundColor: "#80808014" }}>
              <span className='zoomed-percent-font' style={{color: "#808080"}}>{`${determinepercentage(totalcount?.Total,totalcount?.Missing?.count)}%`}</span>
            </div>
          </div>
        </div>

        

        </div>
      </div> ) }


 </div>
    </>
  );
};